exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-implementation-partners-tsx": () => import("./../../../src/pages/services/implementation-partners.tsx" /* webpackChunkName: "component---src-pages-services-implementation-partners-tsx" */),
  "component---src-pages-services-microsoft-consulting-tsx": () => import("./../../../src/pages/services/microsoft-consulting.tsx" /* webpackChunkName: "component---src-pages-services-microsoft-consulting-tsx" */),
  "component---src-pages-services-organizational-change-management-tsx": () => import("./../../../src/pages/services/organizational-change-management.tsx" /* webpackChunkName: "component---src-pages-services-organizational-change-management-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

